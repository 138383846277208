const SETTINGS = '/profile/settings'
const FEEDBACK_FRILL = '/feedback'
const CANCEL_SEQUENCE = '/profile/cancelSequence'
const OLD_GENERATOR = '/generator'
const GENERATOR = '/'
const PRICING = '/pricing'
const OLD_PREVIEW = '/preview'
const PREVIEW = '/p'
const AFFILIATE = '/affiliate'
const LIBRARY = '/profile/library'
const CONTACT_US = '/contact-us'
const TERMS_OF_SERVICE = '/terms-of-service'
const PRIVACY_POLICY = '/privacy-policy'
const BLOG = '/blog'
const FREE_SEO_TOOLS = '/free-music-tools'
const HOW_TO_MAKE_A_SONG_GUIDE = '/how-to-make-a-song'
const EDU = '/edu'
const BLOG_POST = '/blog-post'
const LANDING = '/welcome'
const BOOK_DEMO = 'https://calendly.com/cube-cc/15min-chordchord-demo'
const GET_OFFER = 'https://sbykva6jube.typeform.com/to/icyD2eNL'
const STUDENT_FORM = 'https://forms.gle/4jLMWg4YbZvxbGsg9'
//
const CANVAS = '/canvas'
const CANVAS_VST = '/canvas/vst-plugin-beta'
//
const DIRECT_OFFER = '/offer/direct-v1-924334'
//
const CANVAS_OFFER = '/offer/canvas-special'
const CANVAS_OFFER_CHECKOUT = '/offer/canvas-special/checkout'
const CANVAS_OFFER_SUCCESS = '/offer/canvas-special/thank-you'
const CANVAS_OFFER_OTO = '/offer/canvas-special/oto'
const CANVAS_OFFER_OTO_SUCCESS = '/offer/canvas-special/oto/thank-you'
//
const INVITE = '/invite'
//
const AI_DEMO = '/offer/ai-demo'
//
const SPECIAL_3_MONTHS_OFFER = '/offer/special-3-months'
const SPECIAL_3_MONTHS_OFFER_CHECKOUT = '/offer/special-3-months/checkout'
const SPECIAL_3_MONTHS_OFFER_SUCCESS = '/offer/special-3-months/thank-you'

export default {
  AI_DEMO,
  LANDING,
  SETTINGS,
  FEEDBACK_FRILL,
  CANCEL_SEQUENCE,
  OLD_GENERATOR,
  GENERATOR,
  PRICING,
  OLD_PREVIEW,
  PREVIEW,
  LIBRARY,
  CONTACT_US,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  BOOK_DEMO,
  AFFILIATE,
  GET_OFFER,
  STUDENT_FORM,
  BLOG,
  FREE_SEO_TOOLS,
  EDU,
  BLOG_POST,
  HOW_TO_MAKE_A_SONG_GUIDE,
  FEEDBACK: 'https://forms.gle/syvrnFcpyWXyMvFw6',
  DIRECT_OFFER,
  CANVAS,
  CANVAS_VST,
  CANVAS_OFFER,
  CANVAS_OFFER_SUCCESS,
  CANVAS_OFFER_OTO_SUCCESS,
  CANVAS_OFFER_CHECKOUT,
  CANVAS_OFFER_OTO,
  INVITE,
  SPECIAL_3_MONTHS_OFFER,
  SPECIAL_3_MONTHS_OFFER_CHECKOUT,
  SPECIAL_3_MONTHS_OFFER_SUCCESS,
}
