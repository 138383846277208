import api from './index'

export const logoutInnerRoute = () => {
  return `/logout?redirect=${window.location.pathname}${window.location.search}`
}

export const loginEmailInnerRoute = async (email: string, locale: string) => {
  await api.get(`/login/email/code?redirect=${window.location.pathname}${window.location.search}`, {
    params: { email, locale },
  })
}

export const loginEmailCallbackInnerRoute = async (email: string, code: string) => {
  return await api.get(`/login/email/callback?code=${code}&email=${email}`)
}

export const updateUserNameInnerRoute = async (name: string) => {
  await api.put('/user/update-name', { name })
}

export const loginAppleInnerRoute = () => {
  return `/login/apple?redirect=${window.location.pathname}${window.location.search}`
}

export const loginGoogleInnerRoute = () => {
  return `/login/google?redirect=${window.location.pathname}${window.location.search}`
}

export const loginFacebookInnerRoute = () => {
  return `/login/facebook?redirect=${window.location.pathname}${window.location.search}`
}

export const getUserUsageInnerRoute = async () => {
  const data = await api.get('/api/usage')
  const result = {}
  Object.keys(data?.data?.limits || {}).forEach((k) => {
    // @ts-ignore
    result[k] = { limit: data.data.limits[k], current: data.data.usage[k] }
  })
  return result as any
}

export const isUserSubscribedInnerRoute = async () => {
  const { data: isUserSubscribed } = await api.get('/user/is-subscribed')

  return isUserSubscribed as boolean
}

export const deleteAccountInnerRoute = (): string => {
  return '/user/delete'
}

export const cancelDeleteAccountInnerRoute = (): string => {
  return '/user/delete/cancel'
}

export const isEmailSubscribedInnerRoute = async (email: string) => {
  const { data: isUserSubscribed } = await api.post('/user/is-email-subscribed', { email })

  return isUserSubscribed as boolean
}

export type StatusTypes = 'pending' | 'success' | 'error'

export const trackCancelSequencePaddleStatusInnerRoute = async (status: StatusTypes | 'pageOpen') => {
  await api.post('/user/track-cancel-sequence-paddle-status', { status })
}

export const trackCancelSequenceStripeStatusInnerRoute = async () => {
  await api.post('/user/track-cancel-sequence-stripe-status')
}

export const getLoginLinkInnerRoute = async (email: string) => {
  const data = await api.get('/login/email/link', { params: { email } })

  return data.data
}
