import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'

import {
  loginAppleInnerRoute,
  loginEmailInnerRoute,
  loginFacebookInnerRoute,
  loginGoogleInnerRoute,
  logoutInnerRoute,
  updateUserNameInnerRoute,
} from '../api/auth'
import { windowOpen } from '../utils/stringUtils'
import { FrontendUser, FrontendUserDetailed } from '../utils/types'

type FetchType = {
  currentUser: FrontendUser | FrontendUserDetailed | null | undefined
  logout: () => void
  loginApple: () => void
  loginFacebook: () => void
  loginGoogle: () => void
  loginEmail: (email: string) => Promise<void>
  updateUserName: (name: string) => Promise<void>
}

export const CurrentUserContext = React.createContext<FetchType>({
  currentUser: null,
  logout: () => {},
  loginApple: () => {},
  loginFacebook: () => {},
  loginGoogle: () => {},
  loginEmail: () => Promise.resolve(),
  updateUserName: () => Promise.resolve(),
})

export const CurrentUserProvider = ({ children, user }: { children: ReactNode; user?: FrontendUser }) => {
  const router = useRouter()

  const logout = () => {
    windowOpen(logoutInnerRoute(), '_parent')
  }
  const loginApple = () => {
    windowOpen(loginAppleInnerRoute(), '_parent')
  }
  const loginFacebook = () => {
    windowOpen(loginFacebookInnerRoute(), '_parent')
  }
  const loginGoogle = () => {
    windowOpen(loginGoogleInnerRoute(), '_parent')
  }
  const loginEmail = async (email: string) => {
    await loginEmailInnerRoute(email, router.locale || '')
  }
  const updateUserName = async (name: string) => {
    await updateUserNameInnerRoute(name)
  }
  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: user,
        logout,
        loginEmail,
        loginApple,
        loginFacebook,
        loginGoogle,
        updateUserName,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => React.useContext<FetchType>(CurrentUserContext)
