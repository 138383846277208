import classNames from 'classnames'
import Image from 'next/image'
import React from 'react'

import styles from './CommonHeader.module.scss'

const Logo: React.FC<{ small?: boolean }> = ({ small }) => {
  return (
    <div className={classNames(styles.logoContainer, { [styles.small]: small })}>
      <Image
        src={`/static/images/${small ? 'mobile-logo' : 'logo'}.webp`}
        layout='fill'
        alt='ChordChord'
        priority
        sizes={`(max-width: 576px) ${small ? 16 : 42}px, 128px`}
      />
    </div>
  )
}

export default Logo
