import { useEffect, useState } from 'react'

type SetValue<T> = (value: T | ((prevValue: T) => T)) => void

function useStorageState<T>(defaultValue: T, name: string, setupCB?: (a: T) => void): [T, SetValue<T>] {
  const [valueState, setValueState] = useState<T>(defaultValue)
  useEffect(() => {
    const state = localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name) || '') : defaultValue
    setValueState(state)
    if (setupCB) {
      setupCB(state)
    }
  }, [])
  useEffect(() => {
    localStorage.setItem(name, JSON.stringify(valueState || ''))
  }, [valueState])
  return [valueState, setValueState]
}

export default useStorageState
