import { useRouter } from 'next/router'
import React, { FC, ReactNode, useEffect, useState } from 'react'

import { getPageText } from '../../../utils/nextUtils'

type Props = {
  href: string
  locale?: string
  target?: string
  className?: string
  prefetch?: boolean
  onClick?: any
  children: ReactNode
  [key: string]: any
}

const Link: FC<Props> = ({ children, href: rawHref, locale: rawLocale, ...props }) => {
  const { locale: currentLocale, route, locales } = useRouter()

  const [href, setHref] = useState(rawHref)

  const clearHref = (h: string) => {
    if (!locales) return h
    if (locales.includes(`/${h}`)) return '/'

    let res = h
    locales.forEach((locale) => (res = res.replaceAll(`/${locale}/`, '/')))

    return res
  }
  const createLink = async (locale = '', href = '/') => {
    const isInnerRoute = href.startsWith('/')
    if (!isInnerRoute) return href

    const isEnglish = locale === 'en'
    const translation = await getPageText(locale, href)
    const translationExists = !!Object.keys(translation).length
    const useLocaleInLink = isEnglish ? false : translationExists

    if (useLocaleInLink) return `/${locale}/${clearHref(href)}`.replaceAll('//', '/')
    return clearHref(href).replaceAll('//', '/')
  }

  useEffect(() => {
    ;(async () => {
      let localHref = ''

      if (rawHref && rawLocale) localHref = await createLink(rawLocale, rawHref)
      if (rawHref && !rawLocale) localHref = await createLink(currentLocale, rawHref)
      if (!rawHref && rawLocale) localHref = await createLink(rawLocale, route)
      if (!rawHref && !rawLocale) localHref = await createLink('', route)

      if (href !== localHref) setHref(localHref)
    })()
  }, [currentLocale, route])

  return (
    <a href={href} {...props}>
      {children}
    </a>
  )
}

export default Link
