import axios from 'axios'

import notification from '../components/common/Notification/notification'

const api = axios.create({})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const isApiRequest = error.config.url.startsWith('/api/')

    if (!isApiRequest) throw error

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // notification.error('Something went wrong, please try again')
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      notification.error('Please check your internet connection and try again')
    } else {
      // Something happened in setting up the request that triggered an Error
      notification.error('Something went wrong, please try again')
    }

    throw error
  },
)

export default api
